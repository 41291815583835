import React from 'react'
import List from '../components/List';



const Table = (props) => {
  // console.log("TABLES DATA  " + JSON.stringify(props.data));

  return (
    <div className='user_table'>
        <table className='table table-striped table-responsive-sm'>
            <thead>
                <tr className='tbl_heading'>
                  <th scope="col">Id</th>
                  <th scope='col'>Profile</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <List data={props.data} openDeletModal={props.openDeletModal} openEditModal={props.openEditModal} />
            </tbody>
        </table>
    </div>
  )
}

export default Table;