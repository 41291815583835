import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import '../styles/forgetpassword.css'
import axios from "axios";
import config from "../config";
import Loading from "../components/Loadingbutton";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";

const mainUrl = config.baseurl;


const Forgetpassword = () => {
    const navigate = useNavigate()

    const [showicon, setShowicon] = useState(false)
    const [showdata, setShowdata] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [resetpasswordtext, setResetpasswordtext] = useState({ npassword: '', cpassword: '' });
    const [error, setError] = useState({ errorFor: '', message: '' });
    const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });

    const [usename, setUsername] = useState('');

    const handleiconclick = () => {
        setShowicon(!showicon)
    }

    useEffect(() => {
        verifyToken();
    }, [])

    const verifyToken = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `${mainUrl}/api/verifyToken`,
                data: {
                    token: window.location.search.split('?')[1].split('&')[0].split('=')[1],
                    tokenExpire: window.location.search.split('?')[1].split('&')[1].split('=')[1],
                },
                headers: {
                    "content-type": "application/json",
                    "authorization": window.location.search.split('?')[1].split('&')[0].split('=')[1]
                }
            });
            console.log(response)
            const data = response.data;
            const getusername = response.data.data.admin.username
            const verify = data.data && data.data.verifyToken
            setIsLoading(false)
            if (data.status == 200) {
                if (verify == true) {
                    setShowdata(true)
                    setUsername(getusername)
                } else {
                    console.log('token expired')
                    setShowAlert({ title: "Alert", message: `Token Expired`, type: 'danger', isShow: true });
                }
            }
            else {
                console.log("Invalid token");
                setShowAlert({ title: "Alert", message: `Invalid Token`, type: 'danger', isShow: true });
            }
        }
        catch (error) {
            setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
            console.log('add admin apiErrror ' + error);
        }
    }

    const handleinput = (e) => {
        let length = e.target.value.length;
        let element = document.getElementsByName(e.target.name)[0];
        if (length === 0) {
            // setError({ errorFor: e.target.name, message: 'This field is required!' });
            setResetpasswordtext({ ...resetpasswordtext, [e.target.name]: e.target.value });
        }
        if (length > 0) {
            element.style = 'none';
            // setError({ errorFor: '', message: '' });
            setResetpasswordtext({ ...resetpasswordtext, [e.target.name]: e.target.value });
        }
    }

    const handleresetpasswordform = async (event) => {
        event.preventDefault()
        try {
            if (resetpasswordtext.cpassword === resetpasswordtext.npassword) {
                const response = await axios({
                    method: 'post',
                    url: `${mainUrl}/api/resetPassword`,
                    data: {
                        username: usename,
                        password: resetpasswordtext.cpassword
                    },
                    headers: {
                        "content-type": "application/json",
                        "authorization": window.location.search.split('?')[1].split('&')[0].split('=')[1]
                    }
                });
                const data = response;
                if (data.status === 200) {
                    setShowAlert({ title: "Alert", message: `Password update sucessfully`, type: 'success', isShow: true });
                    setTimeout(() => {
                        return navigate('/login')
                    }, [4000])
                } else {
                    console.log('somthing went wrong')
                    setShowAlert({ title: "Alert", message: `Somthing went wrong`, type: 'danger', isShow: true });
                }
            }
            else {
                setShowAlert({ title: "Alert", message: `Password is not match.`, type: 'danger', isShow: true });
                console.log("password is not match");
            }
        }
        catch (error) {
            setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
            console.log('add admin apiErrror ' + error);
        }
    }

    const handleAlert = () => {
        setShowAlert({ title: '', message: '', type: '', isShow: false })
    }
    return (
        <>
            {showdata ?
                <div className="content">
                    <div className="width1">
                    </div>
                    <div className="width2">
                        <div className="form">
                            <h3 className="Text">Forget password</h3>
                            <form onSubmit={handleresetpasswordform}>
                                <div className="input-container">
                                    <label className="input">New Password</label>
                                    <input className="logininput" type="password" name="npassword" required onChange={handleinput} />
                                </div>
                                <div className="input-container">
                                    <div className="passwordbox">
                                        <label className="input">Confirm Password</label>
                                        <input type={showicon ? "text" : "password"} className="input-field logininput" name="cpassword" required onChange={handleinput} />
                                        {showicon ? <AiOutlineEye className="icons" onClick={handleiconclick} size={30} color="" /> : <AiOutlineEyeInvisible className="icons" size={30} onClick={handleiconclick} />}
                                    </div>
                                </div>
                                <div className="forgetbtncontainer">
                                    <button id="forgetbtn" type="submit">Save</button>
                                </div>
                                <div id="showAlert" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '9px', }}>
                                    {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}
                                </div>
                            </form>
                        </div>
                    </div>
                </div> : isLoading ? <Loading /> : ''}
        </>
    )
}

export default Forgetpassword