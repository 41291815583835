import React, { useState, useEffect } from 'react'
import { MdSearch, MdCalendarMonth, MdEdit, MdDelete } from 'react-icons/md';
import '../styles/reportedmessage.css';
import Search from '../components/Search';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import config from '../config';
import Alert from '../components/Alert';
import Loading from '../components/Loadingbutton';
import Sidebar from '../components/Sidebar';
import { AiOutlineMenu } from 'react-icons/ai';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import Paginationlist from './Paginationlist';


const mainUrl = config.baseurl;

const SpamMessage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [togglesidebar, setTogglesidebar] = useState(true);
  // const [apiCall, setApiCall] = useState({ type: 'post', slug: '', body: {} });
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showDeletModal, setShowDeletModal] = useState(false);
  const [msgId, setMsgId] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSpam, setIsSpam] = useState(true);
  const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, SetSortBy] = useState('');
  const [apiCall, setApiCall] = useState({ type: 'post', slug: 'getAllSpammedMsgs', body: { recordPerPage, pageNumber } });
  const [totaluser, SetTotalUser] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const handleRecordPerPage = (event) => {
    setRecordPerPage(event.target.value)
  }
  const token = localStorage.getItem('token')

  useEffect(() => {
    setIsLoading(true);
    const fetchUsers = async () => {
      try {
        const response = await axios({
          method: apiCall.type,
          url: `${mainUrl}/api/${apiCall.slug}`,
          data: apiCall.body,
          headers: {
            "content-type": "application/json",
            "authorization": `${token}`
          }
        });
        const data = response.data;
        setApiData(data.data);
        setIsLoading(false);
      }
      catch (error) {
        setIsLoading(false);
        setApiError(error);
        console.log('apiErrror ' + error);
        console.log('apiErrror ' + error);
        setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
      }
    }

    fetchUsers();
  }, [apiCall, pageNumber]);

  // const onChange = (event) => {
  //   // console.log(event.target.value);
  //   setSearchText(event.target.value);
  //   setApiCall({ type: 'post', slug: 'filterSpammedMsgBySearch', body: { search: searchText } })
  // }

  const onKeyDown = (e) => {
    setSearchText(e.target.value);
    e.key === 'Enter' && onSearchClick();
  }

  const onSearchClick = (event) => {
    setApiCall({ type: 'post', slug: 'filterSpammedMsgBySearch', body: { search: searchText } })
  }

  const toggleDeletModal = () => {
    return setShowDeletModal(!showDeletModal);
  }

  const openDeletModal = async (id) => {
    // console.log(`delete this user ${id}`);
    toggleDeletModal();
    setMsgId(id);
  }

  const deleteUser = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/deleteSpamMsg`,
        data: {
          messageId: msgId,
        },
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      console.log("rep " + JSON.stringify(data));
      setMsgId("");
      toggleDeletModal();
      setApiCall({ type: 'post', slug: 'getAllSpammedMsgs', body: {} });
      setShowAlert({ title: "Alert", message: `Spam message deleted successfully.`, type: 'success', isShow: true });
    }
    catch (error) {
      setIsLoading(false);
      setApiError(error);
      console.log('apiErrror ' + error);
      setShowAlert({ title: "Alert", message: `Spam message not deleted.`, type: 'danger', isShow: true });
    }
  }

  const toggleEditModal = () => {
    return setShowEditModal(!showEditModal);
  }

  const openEditModal = async (id) => {
    toggleEditModal();
    setMsgId(id);
  }

  const handleSpam = () => {
    setIsSpam(!isSpam);
  }

  const editMessage = async () => {
    // console.log(editUserData);
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/postRemoveSpamFlag`,
        data: {
          messageId: msgId,
          isSpam: isSpam
        },
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      setApiCall({ type: 'post', slug: 'getAllSpammedMsgs', body: {} });
      toggleEditModal();
      setShowAlert({ title: "Alert", message: `Remove spam flag successfully.`, type: 'success', isShow: true });
    }
    catch (error) {
      setIsLoading(false);
      setApiError(error);
      console.log('apiErrror ' + error);
      setShowAlert({ title: "Alert", message: `Spam flag not removed.`, type: 'danger', isShow: true });

    }
  }

  const handleAlert = () => {
    setShowAlert({ title: '', message: '', type: '', isShow: false })
  }

  const handlesidebar = () => {
    if (togglesidebar) setTogglesidebar(false);
    else setTogglesidebar(true)
  }

  const handlecallback = (page) => {
    setPageNumber(page);
  }

  const onDateChangeFrom = () => {
    let date = document.getElementById('search_date_from');
    setDateFrom(date.value);
    console.log("Data From is ", date.value);
  }

  const onDateChangeTo = async () => {
    let date = document.getElementById('search_date_to');
    setDateTo(date.value);
    console.log("Data To is ", date.value);
    setApiCall({ type: 'post', slug: 'filterSpammedMsgByDate', body: { dateFrom, dateTo: date.value, recordPerPage, pageNumber } });
  }

  // useEffect(() => {
  //   setApiCall({ type: 'post', slug: 'getAllSpammedMsgs', body: { recordPerPage, pageNumber } });
  // }, [pageNumber]);


  return (
    <div className='main-container'>
      <Sidebar togglevalue={togglesidebar} />
      <main>
        <div className="container-fluit">
          {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}

          <div onClick={handlesidebar}>
            {togglesidebar ? <div className='toggleicon'><AiOutlineMenu size={30} /> </div> : <div className='crossicon'><RxCross1 size={30} /></div>}
          </div>
          <h1 className="dashboard_heading">Spam Messages</h1>

          <div className="container-users">
            <div className="filter">
              <div className='container-serach'>

                <Search icon={<MdSearch />} id="search_text" type='text' iconId="search_text_icon" onKeyDown={onKeyDown} onSearchClick={onSearchClick} />

                <div className='date_filter' id='reported_datapicker'>
                  <Search icon={<MdCalendarMonth />} id="search_date_from" type='date' iconId="search_date_icon" onChange={onDateChangeFrom} />
                  
                  <Search icon={<MdCalendarMonth />} id="search_date_to" type='date' iconId="search_date_icon" onChange={onDateChangeTo} />
              </div>

              </div>
              <div className='dropdown'>
                <select onChange={handleRecordPerPage} className='custom-select'>
                  <option hidden>Record Per Page</option>
                  <option value="2">10</option>
                  <option value="3">25</option>
                  <option value="5">50</option>
                  <option value="10">100</option>
                </select>
              </div>
            </div>
            <div className='user_table'>
              <table className='table table-striped table-responsive-sm'>
                <thead>
                  <tr className='tbl_heading'>
                    <th>Message</th>
                    <th>Spam Message</th>
                    <th>Date and Time</th>
                    <th>Reported By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <tr><td style={{ backgroundColor: 'white' }} colSpan={5}><Loading /></td></tr> : 
                  apiData && apiData.length ?
                    apiData.map((item, index) => (
                      <tr className='tbl_row' key={index + 1} >
                        <td className='message'> {item.messageText} </td>
                        <td className='message'> {item.spamText} </td>
                        <td> {new Date(parseInt(item.timeStamp)).toLocaleDateString()} </td>
                        <td> {item.senderName} </td>
                        <td className='actions'>
                          <span className='edit_icon action_icons' title='edit message' onClick={() => { openEditModal(item.id) }}> <MdEdit />  </span>
                          <span className='delete_icon action_icons' title='delete message' onClick={() => { openDeletModal(item.id) }}> <MdDelete /> </span>
                        </td>
                      </tr>
                    )) : <tr>
                      <td colSpan={5} style={{ marginLeft: '1rem', textAlign: 'center', backgroundColor: 'white', fontSize: '25px', fontWeight: 'normal' }}>No Spam Messages {searchText.length > 0 && "{searchText}"}</td>
                    </tr>
                  }
                </tbody>

              </table>
            </div>
          </div>

          {totaluser.totalUsers >= 2 ? <Paginationlist recordPerPage={recordPerPage} totaluser={totaluser} handlecallback={handlecallback} /> : ''}

          <Modal show={showDeletModal}>
            <Modal.Header onClick={toggleDeletModal} >
              <Modal.Title>Delete Spam Message</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              Are you want to sure to delete this user?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleDeletModal}>
                Close
              </Button>
              <Button variant="success" onClick={deleteUser}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={showEditModal}>
            <Modal.Header onClick={toggleEditModal} >
              <Modal.Title>Spam Message</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={editMessage}>
                <Form.Group className="mb-3" controlId="Spam">
                  <Form.Check type='checkbox' label="Unspam" name='isSpam' defaultChecked={!isSpam} onClick={handleSpam} required />
                </Form.Group>
                <Form.Group style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" onClick={toggleEditModal} style={{ marginRight: '1rem' }}> Close </Button>
                  <Button variant="success" type='submit' > Save Changes </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </main>
    </div>
  )
}

export default SpamMessage;