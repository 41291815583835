import React from 'react';
import '../styles/search.css';

const Search = ({icon, id, type, onSearchClick, iconId, onKeyDown, onChange}) => {
  return (
    <div className='search_box'>
        <input type={type} name={type} placeholder='Search' className='search' id={id} onKeyDown={onKeyDown} onChange={onChange}/>
        <span className='search_icon' id={iconId} onClick={onSearchClick}>
          { type === 'text' && icon}
        </span>
    </div>
  )
}

export default Search;