import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import '../styles/settings.css';
import { AiFillEye, AiFillEyeInvisible, AiOutlineMenu } from "react-icons/ai";
import axios from 'axios';
import config from '../config';
import Alert from '../components/Alert';
import Sidebar from '../components/Sidebar';
import { RxCross1 } from 'react-icons/rx';

const mainUrl = config.baseurl;

const Setting = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [togglesidebar, setTogglesidebar] = useState(true);
  const [accountData, setAccountData] = useState({ username: '', password: '' });
  const [configurationData, setConfigurationData] = useState({ jwt_secret: '', encrypt_iv: '', encrypt_key: '' });
  const [twilioData, setTwilioData] = useState({ account_sid: '', api_key_sid: '', api_key_secret: '' });
  const [readOnly, setReadOnly] = useState(true);
  const [error, setError] = useState({ errorFor: '', message: '' });
  const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });

  const token = localStorage.getItem('token')

  useEffect(() => {
    const getSettingsData = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${mainUrl}/api/getSettings`,
          data: {},
          headers: {
            "content-type": "application/json",
            "authorization": `${token}`
          }
        });
        const data = response.data.data[0];
        // console.log("twilio " + JSON.stringify(data));
        setTwilioData({
          account_sid: data.integrations.twilio.account_sid,
          api_key_sid: data.integrations.twilio.api_key_sid,
          api_key_secret: data.integrations.twilio.api_key_secret
        })
        setConfigurationData({
          jwt_secret: data.system.jwt_secret,
          encrypt_key: data.system.encrypt_key,
          encrypt_iv: data.system.encrypt_iv,
        })
      }
      catch (error) {
        setShowAlert({ title: "Alert", message: `Please login again`, type: 'danger', isShow: true });
        console.log('apiErrror ' + error);
      }
    }
    getSettingsData();
  }, [])

  const toggleEye = () => {
    setShowPassword(!showPassword);
  }

  const handleInputTwilio = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setTwilioData({ ...twilioData, [e.target.name]: e.target.value });
    }
    if (length > 0) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setTwilioData({ ...twilioData, [e.target.name]: e.target.value });
    }
  }

  const updateTwilio = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/updateTwilioSettings`,
        data: {
          account_sid: twilioData.account_sid,
          api_key_sid: twilioData.api_key_sid,
          api_key_secret: twilioData.api_key_secret
        },
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      console.log("twilio " + JSON.stringify(data));
      setShowAlert({ title: "Alert", message: `Twilio settings updated successfully.`, type: 'success', isShow: true });
    }
    catch (error) {
      setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
      console.log('apiErrror ' + error);
    }
  }

  const handleAdminInput = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAccountData({ ...accountData, [e.target.name]: e.target.value });
    }
    if (length > 0) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAccountData({ ...accountData, [e.target.name]: e.target.value });
    }
  }

  // const handleAdminPassword = (e) => {
  //   let length = e.target.value.length;
  //   let element = document.getElementsByName(e.target.name)[0];
  //   if(length === 0) {
  //     element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
  //     setError({ errorFor: e.target.name, message: 'This field is required!'});
  //     setAccountData({ ...accountData, [e.target.name]: e.target.value });
  //   }
  //   if (length <= 7) {
  //     element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
  //     setError({ errorFor: e.target.name, message: 'Password length must be grater 7'});
  //     setAccountData({ ...accountData, [e.target.name]: e.target.value });
  //   }
  //   if (length > 7) {
  //     element.style = 'none';
  //     setError({errorFor: '', message: ''});
  //     setAccountData({ ...accountData, [e.target.name]: e.target.value });
  //   }
  // }

  const updateAdmin = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/updateAdmin`,
        data: {
          username: accountData.username,
          password: accountData.password
        }, headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      console.log("admin " + JSON.stringify(data));

    } catch (error) {
      setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
    }
  }

  const handleAlert = () => {
    setShowAlert({ title: '', message: '', type: '', isShow: false })
  }

  const handlesidebar = () => {
    if (togglesidebar) setTogglesidebar(false);
    else setTogglesidebar(true)
  }

  return (
    <div className='main-container'>
      <Sidebar togglevalue={togglesidebar} />
      <main>
        <div className='container-center'>
          {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}

          <div onClick={handlesidebar}>
            {togglesidebar ? <div className='toggleicon'><AiOutlineMenu size={30} /> </div> : <div className='crossicon'><RxCross1 size={30} /></div>}
          </div>
          <h1 className='heading'> Settings </h1>
          <div id="form_container">
            <div id="account_form">
              <h4 className='form_heading'>Account Settings</h4>
              <form>
                <div className='form'>
                  <div className="form-group">
                    <label htmlFor="username" className='labletext'>Username</label>
                    <input className="txtbox" type="text" name="username" id="username" value={accountData.username} onChange={handleAdminInput} required />
                  </div>
                  {error.errorFor === "username" && <p style={{ color: 'red', textAlign: 'right' }} > {error.message} </p>}
                  <div className="form-group">
                    <label htmlFor="password" className='labletext'>Password</label>
                    <span className='icon eye' onClick={toggleEye}>
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </span>
                    <input className="txtbox" type={showPassword ? 'text' : 'password'} name="password" id="password" value={accountData.password} onChange={handleAdminInput} required />
                  </div>
                  {error.errorFor === "password" && <p style={{ color: 'red', textAlign: 'right' }} > {error.message} </p>}
                  <div className="btn_wrapper">
                    <Button name={"Save Changes"} onClick={updateAdmin} />
                  </div>
                </div>
              </form>
            </div>


            <div id="configuration_form">
              <h4 className='form_heading'>Configuration Settings</h4>
              <div className='form'>
                <div className="form-group">
                  <label htmlFor="jwt_secret">JWT Secret</label>
                  <input className="txtbox" type="text" name="jwt_secret" id="jwt_secret" value={configurationData.jwt_secret} readOnly />
                </div>
                <div className="form-group">
                  <label htmlFor="encrypt_key">Encrypt Key</label>
                  <input className="txtbox" type="text" name="encrypt_key" id="encrypt_key" value={configurationData.encrypt_key} readOnly />
                </div>
                <div className="form-group">
                  <label htmlFor="encrypt_iv">Encrypt IV</label>
                  <input className="txtbox" type="text" name="encrypt_iv" id="encrypt_iv" value={configurationData.encrypt_iv} readOnly />
                </div>
                <div className="btn_wrapper">
                  <Button name={"Save Changes"} disabled={false} />
                </div>
              </div>
            </div>

            <div id="configuration_form">
              <h4 className='form_heading'>Twillio Settings</h4>
              <form>
                <div className='form'>
                  <div className="form-group">
                    <label htmlFor="acc_sid">Twillio Account SID</label>
                    <input className="txtbox" type="text" name="account_sid" id="acc_sid" value={twilioData.account_sid} onChange={handleInputTwilio} readOnly={readOnly} />
                  </div>
                  {error.errorFor === "account_sid" && <p style={{ color: 'red', textAlign: 'right' }} > {error.message} </p>}
                  <div className="form-group">
                    <label htmlFor="acc_keyid">Twillio Key SID</label>
                    <input className="txtbox" type="text" name="api_key_sid" id="acc_keyid" value={twilioData.api_key_sid} onChange={handleInputTwilio} readOnly={readOnly} />
                  </div>
                  {error.errorFor === "api_key_sid" && <p style={{ color: 'red', textAlign: 'right' }} > {error.message} </p>}
                  <div className="form-group">
                    <label htmlFor="key_secret">Twillio Key Secret</label>
                    <input className="txtbox" type="text" name="api_key_secret" id="key_secret" value={twilioData.api_key_secret} onChange={handleInputTwilio} readOnly={readOnly} />
                  </div>
                  {error.errorFor === "api_key_secret" && <p style={{ color: 'red', textAlign: 'right' }} > {error.message} </p>}
                  <div className="btn_wrapper">
                    {readOnly ? <Button name={"Edit"} onClick={() => { setReadOnly(false) }} /> : <Button name={"Save Changes"} className={"save_changes"} onClick={updateTwilio} />}
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </main>
    </div>
  )
}

export default Setting;