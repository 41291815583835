import React from "react";
import image from "../imgs/3737258.jpg"
import '../styles/pagenotfound.css'
const PageNotFound = () => {
    return (
        <React.Fragment>
            <div id="imgcontainer">
                <img id="img" src={image} alt="" />
            </div>

        </React.Fragment>
    )
}

export default PageNotFound