import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('admin_name');
        localStorage.removeItem('adminVerify');
        localStorage.removeItem('type');
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            console.log("Logout");
        }
    }, []);
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default Logout;