import React, { useEffect, useState }from 'react'
import Sidebar from '../components/Sidebar'
import Alert from '../components/Alert';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import config from '../config';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import Paginationlist from './Paginationlist';
import Loading from '../components/Loadingbutton';
import { MdDelete } from 'react-icons/md';
import {  RxCross2 } from 'react-icons/rx';
// import { Navigate } from 'react-router-dom'

const mainUrl = config.baseurl;

function ManageAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [togglesidebar, setTogglesidebar] = useState(true);
  const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });
  const [error, setError] = useState({ errorFor: '', message: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(null);
  const [adminUserData, setAdminUserData] = useState({ admin_name: "", username: "", password: "", confirm_password: "" });
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, SetSortBy] = useState('');
  const [totalUser, setTotalUser] = useState(0);
  const [apiError, setApiError] = useState('');
  const [apiData, setApiData] = useState([]);
  const [showDeletModal, setShowDeletModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');

  const token = localStorage.getItem('token');

  const fetchData = async (type, slug, body) => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: type,
        url: `${mainUrl}/api/${slug}`,
        data: body,
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      data.totalUsers && setTotalUser(data.totalUsers);
      setIsLoading(false);
      return data;
    }
    catch (error) {
      setIsLoading(false);
      // setApiError(error);
      setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
      console.log('apiErrror ' + error);
    }
  }

  useEffect(() => {
    localStorage.getItem('adminVerify') ? fetchData('post', 'getAllAdmin', { recordPerPage, pageNumber }).then(response => {
      setApiData(response.data);
    }) : window.location.href = '/'
  }, []);

  const handleAlert = () => {
    setShowAlert({ title: '', message: '', type: '', isShow: false })
  }

  const addAdminUser = async (event) => {
    // checkValidation();
    event.preventDefault()
    const body = {
      admin_name: adminUserData.admin_name,
      username: adminUserData.username,
      password: adminUserData.password,
    }

    // console.log(body);
    if (adminUserData.password === '') setError({ errorFor: 'password', message: 'Password required.' })
    if (adminUserData.username === '') setError({ errorFor: 'username', message: 'Username required.' })
    if (adminUserData.admin_name === '') setError({ errorFor: 'admin_name', message: 'Admin name required.' })
    if (adminUserData.confirm_password === '') setError({ errorFor: 'confirm_password', message: 'Confirm password required.' })

    if (adminUserData.password === adminUserData.confirm_password) {
      if (error.message === '') {
        fetchData('post', 'createAdmin', body).then(response => {
          if (response.status ===200) {
            setShowAlert({ title: "Alert", message: `Username already exist`, type: 'danger', isShow: true });
          }
          else if(response.status === 201) {
            setShowAlert({ title: "Alert", message: `Admin created Succesfully`, type: 'success', isShow: true });
            setAdminUserData({admin_name: '',username: '', password: '', confirm_password: ''});
            fetchData('post', 'getAllAdmin', { recordPerPage, pageNumber }).then(response => {
              setApiData(response.data);
            });
          }
          else if (response.status === 400) {
            setShowAlert({ title: "Alert", message: `Admin not created`, type: 'success', isShow: true });
            setAdminUserData({admin_name: '',username: '', password: '', confirm_password: ''});
          }
          else if (response.code === 401) {
            setAdminUserData({admin_name: '',username: '', password: '', confirm_password: ''});
            setShowAlert({ title: "Alert", message: `Please login again`, type: 'danger', isShow: true });
          }
        });
      }
    } else {
      setError({ errorFor: 'confirm_password', message: 'Passsword are not match' });
    }
  }

  const handleAdminInputChange = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }

  const toggleEye = () => {
    setShowPassword(!showPassword);
  }

  const handleAdminPassword = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0 && length <= 7) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'Password length must be grater 7' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 7) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }

  const handleAdminConfirmPassword = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0 && length <= 7) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'Password length must be grater 7' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 7) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }

  const toggleDeletModal = () => {
    return setShowDeletModal(!showDeletModal);
  }

  const openDeletModal = async (id) => {
    // console.log(`delete this user ${id}`);
    toggleDeletModal();
    setDeleteUserId(id);
  }

  const deleteUser = async () => {
    console.log(deleteUserId);
    fetchData('post', 'deleteAdmin', { id: deleteUserId }).then(response => {
      setDeleteUserId("");
      toggleDeletModal();
      if(response.status === 200) {
        setShowAlert({ title: "Alert", message: `Admin deleted successfully.`, type: 'success', isShow: true })
        fetchData('post', 'getAllAdmin', { recordPerPage, pageNumber }).then(response => {
          setApiData(response.data);
        });
      } else {
        setShowAlert({ title: "Alert", message: `Admin not deleted.`, type: 'danger', isShow: true });
      }
    });

  }

  return (
    <div className='main-container'>
      <Sidebar togglevalue={togglesidebar} />
      <main>
        <div className="container-center">       
          {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}

          <h1 className='heading'> Manage Admin</h1>
          <div id="form_container">
            <Form noValidate validated={validated} onSubmit={addAdminUser}>
              <Form.Group className="mb-3" controlId="fullname">
                <Form.Label>Name of Admin: </Form.Label>
                <Form.Control type="text" placeholder="Enter name" name="admin_name" onChange={handleAdminInputChange} required value={adminUserData.admin_name} />
                {error.errorFor === "admin_name" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username: </Form.Label>
                <Form.Control type='text' placeholder="Enter username" name="username" onChange={handleAdminInputChange} required value={adminUserData.username} />
                {error.errorFor === "username" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="password" style={{ position: 'relative'}}>
                <Form.Label>Password</Form.Label>
                <span className='icon eye' onClick={toggleEye} style={{ right: '1rem' }}>
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
                <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Enter password" name="password" onChange={handleAdminPassword} required value={adminUserData.password} />
                {error.errorFor === "password" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="phonenumber">
                <Form.Label>Confirm Password: </Form.Label>
                <Form.Control type='password' placeholder="Enter Confirm Password" name="confirm_password" onChange={handleAdminConfirmPassword} required value={adminUserData.confirm_password} />
                {error.errorFor === "confirm_password" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
              </Form.Group>

              <Form.Group style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className="adminmodal_btn" id="send_btn" type="submit">Add Admin</button>
              </Form.Group>
            </Form>
          </div>
        </div>

        <div className="container-users">
            <div className='user_table table-responsive-md table-responsive-sm'>
              <table className='table table-striped'>
                <thead>
                  <tr className='tbl_heading'>
                    <th scope="col">No.</th>
                    <th scope='col'>Name</th>
                    <th scope="col">Username</th>
                    <th scope="col">Type</th>
                    <th scope="col">CreateAt</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      isLoading ? <tr><td style={{ backgroundColor: 'white' }} colSpan={5}><Loading /></td></tr> :
                      apiData && apiData.length ? 
                            apiData.map((admin, index) => (
                              <tr className='tbl_row' key={index + 1}>
                                <td> {index + 1} </td>
                                <td> {admin.name} </td>
                                <td> {admin.username} </td>
                                <td> {admin.type} </td>
                                <td> {  new Date(parseInt(admin.createdAt)).toLocaleDateString()} </td>
                                <td className='actions'>
                                  <span className='delete_icon action_icons' title='delete user' id={admin.id} onClick={() => { openDeletModal(admin.id) }}> <MdDelete /> </span>
                                </td>
                            </tr>
                            )) : 
                        <tr>
                          <td colSpan={8} style={{ 'fontSize': '1.5rem', backgroundColor: 'white', textAlign: 'center' }}>No Admin found </td>
                        </tr>
                    }
                </tbody>
              </table>
            </div>
        </div>
        <Modal show={showDeletModal}>
            <Modal.Header onClick={toggleDeletModal} >
              <Modal.Title>Delete Admin</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              Are you want to sure to delete this user?
            </Modal.Body>
            <Modal.Footer>
              <button onClick={toggleDeletModal} className="modal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
              <button onClick={deleteUser} className="modal_btn" id="delete_btn" type="submit">Delete</button>
            </Modal.Footer>
        </Modal>  
      </main>
    </div>
  )
}

export default ManageAdmin
