import React, { useEffect, useState } from 'react';
import {
    MdDashboard,
    MdSupervisedUserCircle,
    MdReport,
    MdAdminPanelSettings
} from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";
import { FiLogIn } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import pacakgeJson from '../../package.json'
import "../styles/sidebar.css"
import config from '../config';
import axios from 'axios';

const mainUrl = config.baseurl;


const Sidebar = (props) => {
    const [superAdmin, setSuperAdmin] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const username = localStorage.getItem('username');
            const token = localStorage.getItem('token');
            try {
              const response = await axios({
                method: 'post',
                url: `${mainUrl}/api/getSuperAdmin`,
                body: {username: username},
                headers: {
                  "content-type": "application/json",
                  "authorization": `${token}`,
                }
              });
    
              const data = response.data;
              if(data.status === 200){
                  if(data.data.token === token){
                    setSuperAdmin(true)
                    localStorage.setItem('adminVerify', true)
                  }
              } 
              else{
                  setSuperAdmin(false);
              }
            } catch (error) {
              console.log(error);
            }
        }
        
        fetchData();
        setMenuItems([
            {
                path: '/',
                name: 'Dashboard',
                icon: <MdDashboard />
            },
            {
                path: '/user-directory',
                name: 'User Directory',
                icon: <MdSupervisedUserCircle />
            },
            {
                path: '/spam-message',
                name: 'Spam Message',
                icon: <MdReport />
            },
            {
                path: '/settings',
                name: 'Settings',
                icon: <RiUserSettingsFill />
            },
            {
                path: '/manage-admin',
                name: 'Manage Admin',
                icon: <MdAdminPanelSettings />
            },
            {
                path: '/logout',
                name: 'Logout',
                icon: <FiLogIn />
            },
        ]);
        
      }, []);

    return (
        <React.Fragment>
            <div className={props.togglevalue ? "sidebar" : " mobilesidebar"}>
                <div className='top_section'>
                    <div className='logo_wrapper'>
                        <img className='logo' src="/assets/images/logo.png" alt='Rao logo' />
                    </div>
                    <div className='links'>
                        {
                            superAdmin ?
                                menuItems.map((item, index) => (
                                    <NavLink to={item.path} key={index} className="link" activeclassname="active">
                                        <span className='icon'>{item.icon}</span>
                                        <span className='link_text'>{item.name}</span>
                                    </NavLink>
                                )) : 
                                menuItems.map((item, index) => (
                                    item.path !== '/manage-admin' &&
                                    <NavLink to={item.path} key={index} className="link" activeclassname="active">
                                        <span className='icon'>{item.icon}</span>
                                        <span className='link_text'>{item.name}</span>
                                    </NavLink>
                                ))
                        }
                    </div>
                </div>
                <div className='bottom_section'>
                    <h4 id='version'> V - {pacakgeJson.version}</h4>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar;