import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import UserDirectory from './screens/UserDirectory';
import Login from './screens/Login';
import Setting from './screens/Settings';
import Forgetpassword from './screens/Forgetpassword';
import Privateroute from './components/Privateroute';
import SpamMessage from './screens/SpamMessage';
import Logout from './screens/Logout';
import PageNotFound from './screens/PageNotFound';
import ManageAdmin from './screens/ManageAdmin';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact element={<Privateroute />}>
          <Route path='/' Component={Dashboard} />
          <Route path='/user-directory' Component={UserDirectory} />
          <Route path='/spam-message' Component={SpamMessage} />
          <Route path='/settings' Component={Setting} />
          <Route path='/manage-admin' Component={ManageAdmin} />
          <Route path='/logout' Component={Logout} />
        </Route>
        <Route path='/login' Component={Login} />
        <Route path='/forget-password' Component={Forgetpassword} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
