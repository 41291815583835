import React from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';


const List = (props) => {
  return (
      props.data.map((user, index) => (
        <tr className='tbl_row' key={index + 1}>
            <td> {index + 1} </td>
            <td>
              <a href={user.profilePic} target='_black'>
                <img src={user.profilePic} className='profile' alt='User Profile' style={{cursor:'pointer'}} />
              </a>
            </td>
            <td> {user.fullName} </td>
            <td> {user.phoneNumber} </td>
            <td style={{verticalAlign: 'middle'}}> 
              <span className='user_status'>
                { 
                  (user.isDeleted && "Deleted") || 
                  (user.isBanned && "Banned") ||
                  (user.suspendMeta && user.suspendMeta.isSuspended && "Suspended") ||
                  (user.isReported && "Reported") ||
                  (user.isVerified && "Active")  
                } 
              </span>
            </td>
            <td className='actions'>
                <span className='edit_icon action_icons' title='edit user' id={user.id} onClick={() => { props.openEditModal(user)} }> <MdEdit />  </span>
                <span className='delete_icon action_icons' title='delete user' id={user.id} onClick={() => { props.openDeletModal(user.id)} }> <MdDelete /> </span>
            </td>
        </tr>
    ))
  )
}

export default List;