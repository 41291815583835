import React  from 'react'
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart as ChartJs } from 'chart.js/auto';

const RenderChat = ({type, charData, options}) => {
 
    return (
      <React.Fragment>
      {type.toLowerCase() === 'bar' && <Bar data={charData} draggable={true} />}
      {type.toLowerCase() === 'line' && <Line data={charData} draggable={true} />}
      {type.toLowerCase() === 'doughnut' && <Doughnut data={charData} draggable={true} />}
      {type.toLowerCase() === 'pie' && <Pie data={charData} draggable={true} />}
      </React.Fragment>
    )
}

export default RenderChat;