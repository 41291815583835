import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import '../styles/Pagination.css'


function Paginationlist({ recordPerPage, totalUser, handlecallback, pageNumber }) {

    const [selectnumber, setSelectNumber] = useState('')
    const pageCount = parseInt( Math.ceil(totalUser / recordPerPage) ); 
    const handlePageClick = (event) => {
        handlecallback(event.selected + 1)
        setSelectNumber(event.selected + 1)
    };

    useEffect(() => {
        setSelectNumber(selectnumber)
    }, [selectnumber])

    return (
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                disabledClassName={"pagination__link--disabled"}
                pageCount={pageCount}
                previousLabel="< previous"
                previousLinkClassName="pagination_link"
                nextLinkClassName="pagination_link"
                activeClassName={((pageNumber == selectnumber) || pageNumber == 1) ? "pagination__link--active" : ''}
                renderOnZeroPageCount={null}
                className='pagination'
            />
    );
}

export default Paginationlist
