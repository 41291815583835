import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'


export default function Privateroute() {
    let token = localStorage.getItem("token") === null ? false : true;
    return (
        <React.Fragment>
            {token ? <Outlet /> : <Navigate to="/login" />}
        </React.Fragment>

    )

}