import React, { useState, useEffect } from 'react'
import Card from '../components/Card';
import '../styles/dashborad.css';
import { FaUser } from 'react-icons/fa';
import { MdSearch, MdAnnouncement, MdCalendarMonth } from 'react-icons/md';
import { RiChatCheckFill } from 'react-icons/ri';
import { TbUserExclamation } from 'react-icons/tb';
import { HiOutlineMenu } from 'react-icons/hi'
import RenderChat from '../components/RenderChat';
import Search from '../components/Search';
// import Table from '../components/Table';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Alert from '../components/Alert';
import { MdEdit, MdDelete } from 'react-icons/md';
import config from '../config';
import Sidebar from '../components/Sidebar';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import { AiOutlineMenu } from 'react-icons/ai';
import Paginationlist from './Paginationlist';
import Loading from '../components/Loadingbutton';
import moment from 'moment';

const mainUrl = config.baseurl;
const groupData = [];
const usersData = [];

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [togglesidebar, setTogglesidebar] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  // const [activeUserData, setActiveUserData] = useState([]);
  const [reportedUserData, setReportedUserData] = useState([]);
  const [cardCount, setCardCount] = useState({});
  // const [sentMessageData, setSentMessageData] = useState([]);
  // const [spamMessageData, setSpamMessageData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showDeletModal, setShowDeletModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [isReported, setIsReported] = useState(true);
  const [userData, setUserData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });
  const [error, setError] = useState({ errorFor: '', message: '' });
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, SetSortBy] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [totalUser, setTotalUser] = useState(0);

  // eslint-disable-next-line
  const [barCharData, setBarChartData] = useState({
    labels: groupData.map(item => item._id),
    datasets: [
      {
        label: "Groups",
        data: groupData.map(item => item.groups),
        borderColor: "#004D25",
        backgroundColor: "#e4ede8",
        borderRadius: 50,
        borderWidth: 2,
        options: {
          aspectRatio: 2,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              }
            }
          }
        }
      }
    ],
  });

  // eslint-disable-next-line
  const [lineChartData, setLineChartData] = useState({
    labels: usersData.map(item => item._id),
    datasets: [
      {
        label: "Users",
        data: usersData.map(item => item.users),
        borderColor: 'rgba(0, 77, 37, 0.75)',
        backgroundColor: 'rgba(0, 77, 37, 0.35)',
        tension: 0.3,
        borderWidth: 2,
        options: {
          aspectRatio: 2,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              }
            }
          },
        }
      }
    ]
  });

  const handledropdown = (event) => {
    setRecordPerPage(event.target.value)
  }
  const token = localStorage.getItem('token')

  const fetchData = async (type, slug, body) => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: type,
        url: `${mainUrl}/api/${slug}`,
        data: body,
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      data.totalUsers && setTotalUser(data.totalUsers);
      setIsLoading(false);
      return data.data;
    }
    catch (error) {
      setIsLoading(false);
      // setApiError(error);
      console.log('apiErrror ' + error);
      // setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
    }
  }

  useEffect(() => {
    fetchData('get', 'getUserAndMessageCount', {}).then(response => {
      response ? setCardCount(response) : setCardCount([]);
    }).catch(error => setApiError(error));

    fetchData('post', 'getCurrentUsers', {
      users: 'ReportedUsers', recordPerPage, pageNumber
    }).then(response => {
      response ? setReportedUserData(response) : setReportedUserData([]);
      response ? setApiData(response) : setApiData([])
    }).catch(error => setApiError(error));

    const usersYearWise = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${mainUrl}/api/getUsersYearWise`,
          headers: {
            "content-type": "application/json",
            "authorization": `${token}`
          },
          data: {}
        });
        const data = response.data;
        // console.log("Users year wise " + JSON.stringify(data));
        setLineChartData({
          labels: data.data.map(item => item._id),
          datasets: [
            {
              label: "Users",
              data: data.data.map(item => item.users),
              borderColor: 'rgba(0, 77, 37, 0.75)',
              backgroundColor: 'rgba(0, 77, 37, 0.35)',
              tension: 0.3,
              borderWidth: 2,
              options: {
                aspectRatio: 2,
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: false,
                    }
                  }
                }
              }
            }
          ]
        }
        );
      }
      catch (error) {
        setApiError(error);
        setShowAlert({ title: "Alert", message: `Please login again`, type: 'danger', isShow: true });
        console.log('apiErrror ' + error);
      }
    }
    usersYearWise();

    const groupsYearWise = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${mainUrl}/api/getGroupsYearWise`,
          headers: {
            "content-type": "application/json",
            "authorization": `${token}`
          },
          data: {}
        });
        const data = response.data;
        // console.log("groups wise data " + JSON.stringify(data));
        setBarChartData({
          labels: data.data.map(item => item._id),
          datasets: [
            {
              label: "Groups",
              data: data.data.map(item => item.groups),
              borderColor: "#004D25",
              backgroundColor: "#e4ede8",
              borderRadius: 50,
              borderWidth: 2,
              options: {
                aspectRatio: 2,
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: false,
                    }
                  }
                }
              }
            }
          ],
        })
      }
      catch (error) {
        setApiError(error);
        setShowAlert({ title: "Alert", message: `Please login again`, type: 'danger', isShow: true });
        console.log('apiErrror ' + error);
      }
    }
    groupsYearWise();

    setIsLoading(false);

  }, []);

  // const updateBarChatData = (type, data) => {
  //   // eslint-disable-next-line
  //     setBarChartData({
  //       labels : data.map(item => item._id),
  //       datasets: [
  //           {
  //               label: "Groups",
  //               data: data.map(item => item.groups),
  //               borderColor: "#004D25",
  //               backgroundColor: "#e4ede8",
  //               borderRadius: 50,
  //               borderWidth: 2,
  //               options: {
  //                 aspectRatio: 2,
  //                 responsive: true,
  //                 scales: {
  //                   x: {
  //                     grid: {
  //                       display: false,
  //                     }
  //                   }
  //                 }
  //               }
  //           }
  //       ],
  //     })
  // }

  const clearFilter = async (response) => {
    setApiData(reportedUserData);
    response ? setApiData(reportedUserData) : setApiData([])
  }

  // const onSearchChange = async (event) => {
  //   setSearchText(event.target.value);
  //   event.target.value.length > 0 ? fetchData('post', 'filterReportedUsersBySearch', { search: searchText }).then(response => { response ? setApiData(response) : setApiData([]) }).catch(error => setApiError(error)) : clearFilter();
  // }

  const onKeyDown = async (e) => {
    setSearchText(e.target.value);
    e.key === 'Enter' && onSearchClick();
  }

  const onSearchClick = (event) => {
    searchText.length > 0 ? fetchData('post', 'filterReportedUsersBySearch', { search: searchText }).then(response => { response ? setApiData(response) : setApiData([]) }).catch(error => setApiError(error)) : clearFilter();
  }

  const toggleDeletModal = () => {
    return setShowDeletModal(!showDeletModal);
  }

  const openDeletModal = async (id) => {
    // console.log(`delete this user ${id}`);
    toggleDeletModal();
    setDeleteUserId(id);
  }

  const deleteUser = async () => {
    fetchData('post', 'postDeleteUser', { userId: deleteUserId }).then(response => {
      setDeleteUserId("");
      toggleDeletModal();
      setShowAlert({ title: "Alert", message: `${response.fullName} deleted successfully.`, type: 'success', isShow: true });
      fetchData('get', 'getCurrentUsers', {}).then(response => {
        // response ? setApiData(response) : setApiData([])
        console.log(response);
      }).catch(error => {
        setApiError(error);
        setShowAlert({ title: "Alert", message: "User not deleted.", type: 'danger', isShow: true });
      });
    }).catch(error => { setApiError(error); setIsLoading(false) });
  }


  const toggleEditModal = () => {
    return setShowEditModal(!showEditModal);
  }

  const openEditModal = async (user) => {
    toggleEditModal();
    setUserData(user);
    setIsReported(user.isReported);
  }

  const editUser = async () => {
    console.log(isReported);
    fetchData('post', 'postRemoveReportFlag', {
      userId: userData.id,
      isReported: isReported,
    }).then(response => {
      toggleEditModal();
      setShowAlert({ title: "Alert", message: `${response.fullName} edited successfully.`, type: 'success', isShow: true });
      // console.log(response);
      fetchData('get', 'getCurrentUsers', {users: 'ReportedUsers', recordPerPage, pageNumber}).then(response => {
        setApiData(response);
      }).catch(error => setApiError(error));
    }).catch(error => setApiError(error));
  }

  const handleIsReported = () => {
    setIsReported(!isReported);
  }

  const onDateChangeFrom = () => {
    let date = document.getElementById('search_date_from');
    setDateFrom(date.value);
    console.log("Data From is ", date.value);
  }

  const onDateChangeTo = async () => {
    let date = document.getElementById('search_date_to');
    setDateTo(date.value);
    console.log("Data To is ", date.value);
    fetchData('post', 'filterReportedUsersByDate', { dateFrom, dateTo: date.value, recordPerPage, pageNumber, sortBy }).then(response => setApiData(response)).catch(error => setApiError(error))
  }

  const handleAlert = () => {
    setShowAlert({ title: '', message: '', type: '', isShow: false })
  }

  const handlesidebar = () => {
    if (togglesidebar) setTogglesidebar(false);
    else setTogglesidebar(true)
  }

  const handlecallback = (page) => {
    console.log(`page is `, page)
    setPageNumber(page)
  }

  useEffect(() => {
    fetchData('post', 'getCurrentUsers', {
      users: 'ReportedUsers', recordPerPage, pageNumber
    })
  }, [pageNumber])

  return (
    <div className="main-container">
      <Sidebar togglevalue={togglesidebar} />
      <main>
        <div className="container-fluit">
          {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}
          <div onClick={handlesidebar}>
            {togglesidebar ? <div className='toggleicon'><AiOutlineMenu size={30} /> </div> : <div className='crossicon'><RxCross1 size={30} /></div>}
          </div>
          <h1 className="dashboard_heading">Dashboard</h1>
          <div className="card_section">
            <Card title="Active Users" total={cardCount.activeUsers} icon={<FaUser className='card_icon' />} />
            <Card title="Reported Users" total={cardCount.reportedUsers} icon={<TbUserExclamation className='card_icon' />} />
            <Card title="Sent Messages" total={cardCount.totalMessages} icon={<RiChatCheckFill className='card_icon' />} />
            <Card title="Spamed Messages" total={cardCount.spamMessages} icon={<MdAnnouncement className='card_icon' />} />
          </div>
          <div className='graph_section'>
            <div className='line_chart chart'>
              <RenderChat charData={lineChartData} type="line" />
            </div>
            <div className="bar_chart chart">
              <RenderChat charData={barCharData} type="bar" />
            </div>
          </div>

          <div className="container-users">
            <div className="container-serach">
              <Search icon={<MdSearch />} id="search_text" type="text" iconId="search_text_icon" onKeyDown={onKeyDown} onSearchClick={onSearchClick} />
              
              <div className='date_filter' id='reported_datapicker'>
                <Search icon={<MdCalendarMonth />} id="search_date_from" type='date' iconId="search_date_icon" onChange={onDateChangeFrom} />
                
                <Search icon={<MdCalendarMonth />} id="search_date_to" type='date' iconId="search_date_icon" onChange={onDateChangeTo} />
              </div>

            </div>
            <div className='dropdown'>
              <select name="" id="" onChange={handledropdown} className='custom-select'>
                <option hidden>Record Per Page</option>
                <option value="2">10</option>
                <option value="3">25</option>
                <option value="5">50</option>
                <option value="10">100</option>
              </select>
            </div>
            <div className='user_table table-responsive-md table-responsive-sm'>
              <table className='table table-striped'>
                <thead>
                  <tr className='tbl_heading'>
                    <th scope="col">No.</th>
                    <th scope='col'>Profile</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Reported Message</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <tr><td style={{ backgroundColor: 'white' }} colSpan={5}><Loading /></td></tr> : apiData && apiData.length ?
                    apiData.map((user, index) => (
                      <tr className='tbl_row' key={index + 1}>
                        <td> {index + 1} </td>
                        <td>
                          <a href={user.profilePic} target='_black'>
                            <img src={user.profilePic} className='profile' alt='User Profile' style={{ cursor: 'pointer' }} />
                          </a>
                        </td>
                        <td> {user.fullName} </td>
                        <td> {user.phoneNumber} </td>
                        <td> {user.reportMeta[0].message} </td>
                        <td> {  new Date(parseInt(user.reportMeta[0].date)).toLocaleDateString()} </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <span className='user_status'>
                            {
                              (user.isDeleted && "Deleted") ||
                              (user.isBanned && "Banned") ||
                              (user.suspendMeta && user.suspendMeta.isSuspended && "Suspended") ||
                              (user.isReported && "Reported") ||
                              (user.isVerified && "Active")
                            }
                          </span>
                        </td>
                        <td className='actions'>
                          <span className='edit_icon action_icons' title='edit user' id={user.id} onClick={() => { openEditModal(user) }}> <MdEdit />  </span>
                          <span className='delete_icon action_icons' title='delete user' id={user.id} onClick={() => { openDeletModal(user.id) }}> <MdDelete /> </span>
                        </td>
                      </tr>
                    )) : <tr>
                          <td colSpan={8} style={{ 'fontSize': '1.5rem', backgroundColor: 'white', textAlign: 'center' }}>No user found "{searchText}"</td>
                        </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>

          <Paginationlist recordPerPage={recordPerPage} totalUser={totalUser} handlecallback={handlecallback} pageNumber={pageNumber} /> 
          <Modal show={showDeletModal}>
            <Modal.Header onClick={toggleDeletModal} >
              <Modal.Title>Delete Reported User</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              Are you want to sure to delete this user?
            </Modal.Body>
            <Modal.Footer>
              <button onClick={toggleDeletModal} className="modal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
              <button onClick={deleteUser} className="modal_btn" id="delete_btn" type="submit">Delete</button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal}>
            <Modal.Header onClick={toggleEditModal} >
              <Modal.Title>Edit User</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="Reported">
                  <Form.Check type='checkbox' label="Remove from reported user" name='isReported' defaultChecked={isReported} onClick={handleIsReported} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer >
              <button onClick={toggleEditModal} className="adminmodal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
              <button onClick={editUser} className="adminmodal_btn" id="send_btn" type="submit">update user</button>
            </Modal.Footer>
          </Modal>
        </div>
      </main>
    </div>
  )
}

export default Dashboard;