import React, { useState } from 'react';
import  '../styles/alert.css'
function Alert({ title, message, type, isShow, handleAlert }) {

  // const [show, setShow] = useState(true);

  // setTimeout(() => {
  //   setShow(false);
  // }, 5000);


  // console.log(isShow);

  return (
    <div className='min_alert' >
      <div className={`alert alert-${type} alert-dismissible fade ${isShow ? 'show' : 'hide'}`} role="alert">
         {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleAlert}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
}

export default Alert

// background-repeat: no-repeat;
// margin: 92px 0px;