import React from 'react'

const Card = ({ title, total, icon }) => {
  return (
    <div className="card">
        <div className="row">
            <div className="col-md-6">
                <h5 className="card_title"> {title} </h5>
                <span className="total"> {total} </span>
            </div>
            <span className="col-md-6"> {icon} </span>
        </div>
    </div>
  )
}

export default Card;