import React, { useState, useEffect } from 'react'
import Search from '../components/Search';
import { MdSearch, MdCalendarMonth } from 'react-icons/md';
import Table from '../components/Table';
import '../styles/userdirectory.css';
import '../styles/login.css'
import axios from 'axios';
import { Modal, Button, Pagination } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CustomButton from '../components/Button';
import { AiFillEye, AiFillEyeInvisible, AiOutlineMenu } from "react-icons/ai";
import { RxCross1, RxCross2 } from 'react-icons/rx'
import config from '../config';
import moment from 'moment';
import Alert from '../components/Alert';
import Loading from '../components/Loadingbutton';
import Sidebar from '../components/Sidebar';
import Paginationlist from './Paginationlist';

const mainUrl = config.baseurl;

const UserDirectory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [togglesidebar, setTogglesidebar] = useState(true);
  const [apiError, setApiError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showDeletModal, setShowDeletModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserData, setEditUserData] = useState({ fullName: '', phoneNumber: '', info: '', isBanned: false, isSuspended: false, isReported: false, expires: '' });
  const [userData, setUserData] = useState({});
  const [activeUser, setActiveUser] = useState('AllUsers');
  const [showAdminAddModal, setShowAdminAddModal] = useState(false);
  const [adminUserData, setAdminUserData] = useState({ admin_name: "", username: "", password: "", confirm_password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });
  const [error, setError] = useState({ errorFor: '', message: '' });
  const [validated, setValidated] = useState(null);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, SetSortBy] = useState('');
  const [apiCall, setApiCall] = useState({ type: 'post', slug: 'getCurrentUsers', body: { users: activeUser, recordPerPage, pageNumber } });
  const [totalUser, setTotalUser] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const token = localStorage.getItem('token')

  const handleRecordPerPage = (event) => {
    setRecordPerPage(event.target.value)
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios({
          method: apiCall.type,
          url: `${mainUrl}/api/${apiCall.slug}`,
          data: apiCall.body,
          headers: {
            "content-type": "application/json",
            "authorization": `${token}`
          },
        });
        const data = response.data;
        setTotalUser(data.totalUsers);
        setApiData(data.data);
        setIsLoading(false);
      }
      catch (error) {
        setIsLoading(false);
        setApiError(error);
        setShowAlert({ title: "Alert", message: `Internal Server Error`, type: 'danger', isShow: true });
        console.log('apiErrror ' + error);
      }
    }
    fetchData();
  }, [apiCall, pageNumber, recordPerPage]);

  const activeUserClass = (id) => {
    let active = document.getElementById(id);
    const elements = document.getElementsByClassName('users');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('selected_users');
      active.classList.add("selected_users");
    }
  }

  const handleAllUsers = () => {
    activeUserClass("all");
    setActiveUser('AllUsers');
    setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: 'AllUsers', recordPerPage, pageNumber } });
  }

  const handleBannedUsers = () => {
    activeUserClass("banned");
    setActiveUser('BannedUsers');
    setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: 'BannedUsers', recordPerPage, pageNumber } });
  }

  const handleSuspendedUsers = () => {
    activeUserClass("suspended");
    setActiveUser('SuspendedUsers');
    setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: 'SuspendedUsers', recordPerPage, pageNumber } });
  }

  const handleDeletedUsers = () => {
    activeUserClass("deleted");
    setActiveUser('DeletedUsers');
    setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: 'DeletedUsers', recordPerPage, pageNumber } });
  }

  const handleReportedUsers = () => {
    activeUserClass("reported");
    setActiveUser('ReportedUsers');
    setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: 'ReportedUsers', recordPerPage, pageNumber } });
  }

  const clearFilter = async () => {
    setApiCall({ type: 'get', slug: activeUser, body: {} });
  }

  // const onSearchChange = (event) => {
  //   setSearchText(event.target.value);
  //   event.target.value.length > 0 ? setApiCall({ type: 'post', slug: 'filterUserBySearch', body: { search: searchText } }) : clearFilter();
  // }

  const onKeyDown = (e) => {
    setSearchText(e.target.value);
    e.key === 'Enter' && onSearchClick();
  }

  const onSearchClick = (event) => {
    setApiCall({ type: 'post', slug: 'filterUserBySearch', body: { search: searchText, users: activeUser } });
  }

  const toggleDeletModal = () => {
    return setShowDeletModal(!showDeletModal);
  }

  const openDeletModal = async (id) => {
    toggleDeletModal();
    setDeleteUserId(id);
  }

  const deleteUser = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/postDeleteUser`,
        data: {
          userId: deleteUserId,
        },
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      setDeleteUserId("");
      toggleDeletModal();
      setShowAlert({ title: "Alert", message: `${data.data.fullName} is deleted successfully.`, type: 'success', isShow: true });
    }
    catch (error) {
      setIsLoading(false);
      setApiError(error);
      setShowAlert({ title: "Alert", message: 'Internal Server Error', type: 'danger', isShow: true });
      console.log('apiErrror ' + error);
    }
  }

  const toggleEditModal = () => {
    return setShowEditModal(!showEditModal);
  }

  const openEditModal = async (user) => {
    toggleEditModal();
    setUserData(user);
    setEditUserData({
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      info: user.info,
      isBanned: user.isBanned,
      isSuspended: user.suspendMeta && user.suspendMeta.isSuspended,
      expires: user.suspendMeta && user.suspendMeta.expires,
      isReported: user.isReported,
    });
    // console.log(editUserData);
  }

  const editUser = async () => {
    // console.log(editUserData);
    const body = {
      userId: userData.id,
      fullName: editUserData.fullName,
      phoneNumber: editUserData.phoneNumber,
      info: editUserData.info,
      isBanned: editUserData.isBanned,
      isReported: editUserData.isReported,
      isSuspended: editUserData.isSuspended,
      expires: editUserData.expires,
    }
    try {
      const response = await axios({
        method: 'post',
        url: `${mainUrl}/api/editUser`,
        data: body,
        headers: {
          "content-type": "application/json",
          "authorization": `${token}`
        }
      });
      const data = response.data;
      setApiCall({ ...apiCall });
      setShowAlert({ title: "Alert", message: `${data.data.fullName} edited successfully.`, type: 'success', isShow: true });
      toggleEditModal();

    }
    catch (error) {
      setIsLoading(false);
      setApiError(error);
      setShowAlert({ title: "Alert", message: "User not edited.", type: 'danger', isShow: true });
    }
  }

  const handleEditInputChange = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setEditUserData({ ...editUserData, [e.target.name]: e.target.value });
      console.log('length ' + length);
    }
    if (length > 0) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setEditUserData({ ...editUserData, [e.target.name]: e.target.value });
      console.log('length > ' + length);

    }
  }

  const handleIsBanned = () => {
    setEditUserData({ ...editUserData, isBanned: !editUserData.isBanned });
  }

  const handleIsSuspended = () => {
    setEditUserData({ ...editUserData, isSuspended: !editUserData.isSuspended });
  }

  const handleIsReported = () => {
    setEditUserData({ ...editUserData, isReported: !editUserData.isReported });
  }

  const handleExpires = () => {
    let date = document.getElementsByName('expires')[0];
    setEditUserData({ ...editUserData, expires: date.value })
  }

  const toggleEye = () => {
    setShowPassword(!showPassword);
  }

  const toggleAdminAddModal = () => {
    return setShowAdminAddModal(!showAdminAddModal);
  }

  const openAdminAddModal = () => {
    toggleAdminAddModal();
  }

  const handleAdminInputChange = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }

  const handleAdminPassword = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0 && length <= 7) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'Password length must be grater 7' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 7) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }

  const handleAdminConfirmPassword = (e) => {
    let length = e.target.value.length;
    let element = document.getElementsByName(e.target.name)[0];
    if (length === 0) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'This field is required!' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 0 && length <= 7) {
      element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
      setError({ errorFor: e.target.name, message: 'Password length must be grater 7' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
    if (length > 7) {
      element.style = 'none';
      setError({ errorFor: '', message: '' });
      setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
    }
  }
  // const checkValidation = () => {
  //   let length = adminUserData.admin_name.length;
  //   if(length === 0) {
  //     element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
  //     setError({ errorFor: e.target.name, message: 'This field is required!'});
  //     setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
  //   }
  //   if (length > 0 && length <= 7) {
  //     element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
  //     setError({ errorFor: e.target.name, message: 'Password length must be grater 7'});
  //     setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
  //   }
  //   if (length > 7) {
  //     element.style = 'none';
  //     setError({errorFor: '', message: ''});
  //     setAdminUserData({ ...adminUserData, [e.target.name]: e.target.value });
  //   }
  // }

  const addAdminUser = async (event) => {
    // checkValidation();
    event.preventDefault()
    const body = {
      admin_name: adminUserData.admin_name,
      username: adminUserData.username,
      password: adminUserData.password,
    }

    if (adminUserData.password === '') setError({ errorFor: 'password', message: 'Password required.' })
    if (adminUserData.username === '') setError({ errorFor: 'username', message: 'Username required.' })
    if (adminUserData.admin_name === '') setError({ errorFor: 'admin_name', message: 'Admin name required.' })
    if (adminUserData.confirm_password == '') setError({ errorFor: 'confirm_password', message: 'Confirm password required.' })

    if (adminUserData.password === adminUserData.confirm_password) {
      if (error.message == '') {
        try {
          const response = await axios({
            method: 'post',
            url: `${mainUrl}/api/createAdmin`,
            data: body,
            headers: {
              "content-type": "application/json",
              "authorization": `${token}`
            }
          });

          const data = response.data;
          if (data.status === 200) {
              setShowAlert({ title: "Alert", message: `Username already exist`, type: 'danger', isShow: true });
              toggleAdminAddModal();
            } 
          if(data.status === 201) {
              setShowAlert({ title: "Alert", message: `Admin created Succesfully`, type: 'success', isShow: true });
              toggleAdminAddModal()
            }
          if (data.status === 400) {
              setShowAlert({ title: "Alert", message: `Admin not created`, type: 'success', isShow: true });
            }
          
          // toggleAdminAddModal();
        }
        catch (error) {
          setShowAlert({ title: "Alert", message: `Server Internal Error`, type: 'danger', isShow: true });
          console.log('add admin apiErrror ' + error);
        }
      }
    } else {
      setError({ errorFor: 'confirm_password', message: 'Passsword are not match' })
      console.log(`error`, error)
    }
  }

  const handleAlert = () => {
    setShowAlert({ title: '', message: '', type: '', isShow: false })
  }

  const handlesidebar = () => {
    if (togglesidebar) setTogglesidebar(false);
    else setTogglesidebar(true)
  }

  const handlecallback = (page) => {
    setPageNumber(page);
  }

  // const onDateChange = () => {
  //   let date = document.getElementById('search_date');
  //   setApiCall({ type: 'post', slug: 'filterReportedUsersByDate', body: { date: date.value } });
  // }

  const onDateChangeFrom = () => {
    let date = document.getElementById('search_date_from');
    setDateFrom(date.value);
    console.log("Data From is ", date.value);
  }

  const onDateChangeTo = async () => {
    let date = document.getElementById('search_date_to');
    setDateTo(date.value);
    console.log("Data To is ", date.value);
    setApiCall({ type: 'post', slug: 'filterUsersByDate', body: { activeUser, dateFrom, dateTo: date.value, recordPerPage, pageNumber } });
  }

  // useEffect(() => {
  //   setApiCall({ type: 'post', slug: 'getCurrentUsers', body: { users: activeUser, recordPerPage, pageNumber } });
  // }, [pageNumber, recordPerPage])

  return (
    <div className='main-container'>
      <Sidebar togglevalue={togglesidebar} />

      <main>
        <div className="container-fluit">
          <div onClick={handlesidebar}>
            {togglesidebar ? <div className='toggleicon'><AiOutlineMenu size={30} /> </div> : <div className='crossicon'><RxCross1 size={30} /></div>}
          </div>
          {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}

          <div id='admin_btn'>
            <h2 id="userdirectory_heading">User Directory</h2>
            <CustomButton name='Add Admin' onClick={openAdminAddModal} />
          </div>

          <div className="container-users">
            <div className='container-serach'>
              <Search icon={<MdSearch />} id="search_text" type='text' iconId="search_text_icon" onKeyDown={onKeyDown} onSearchClick={onSearchClick} />
              
              <div className='date_filter' id='reported_datapicker'>
                <Search icon={<MdCalendarMonth />} id="search_date_from" type='date' iconId="search_date_icon" onChange={onDateChangeFrom} />
                
                <Search icon={<MdCalendarMonth />} id="search_date_to" type='date' iconId="search_date_icon" onChange={onDateChangeTo} />
              </div>
              
            </div>
            <div className='dropdown'>
              <select name="" id="" onChange={handleRecordPerPage} className='custom-select'>
                <option hidden>Record Per Page</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="filter_section">
              <button className='user_status users selected_users' id="all" onClick={handleAllUsers}>All</button>
              <button className='user_status users' id="banned" onClick={handleBannedUsers}>Banned</button>
              <button className='user_status users' id="suspended" onClick={handleSuspendedUsers}>Suspended</button>
              <button className='user_status users' id="deleted" onClick={handleDeletedUsers}>Deleted</button>
              <button className='user_status users' id="reported" onClick={handleReportedUsers}>Reported</button>
            </div>
            {isLoading
              ? <Loading />
              : apiData && apiData.length > 0
                ? <Table data={apiData} openDeletModal={openDeletModal} openEditModal={openEditModal} />
                : <h3 style={{ marginLeft: '1rem', textAlign: 'center' }}> No user found "{searchText}" </h3>}
          </div>
        
          <Paginationlist recordPerPage={recordPerPage} totalUser={totalUser} handlecallback={handlecallback} pageNumber={pageNumber} /> 

          <Modal show={showDeletModal}>
            <Modal.Header onClick={toggleDeletModal} >
              <Modal.Title>Delete User</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              Are you want to sure to delete this user?
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={toggleDeletModal}>
                Close
              </Button>
              <Button variant="success" onClick={deleteUser}>
                Delete
              </Button> */}
              <button onClick={toggleDeletModal} className="modal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
              <button onClick={deleteUser} className="modal_btn" id="delete_btn" type="submit">Delete</button>
            </Modal.Footer>
          </Modal>


          <Modal show={showEditModal}>
            <Modal.Header onClick={toggleEditModal} >
              <Modal.Title>Edit User</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="fullname">
                  <Form.Label>Name: </Form.Label>
                  <Form.Control type="text" placeholder="Enter full name" name="fullName" onChange={handleEditInputChange} value={editUserData.fullName} />
                  {error.errorFor === "fullName" && <p style={{ color: 'red' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="phonenumber">
                  <Form.Label>Phone Number: </Form.Label>
                  <Form.Control type='phone' placeholder="1234567890" pattern='[0-9]{10}' name="phoneNumber" value={editUserData.phoneNumber} onChange={handleEditInputChange} />
                  {error.errorFor === "phoneNumber" && <p style={{ color: 'red' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="Reported">
                  <Form.Check type='checkbox' label="Reported" name='isReported' defaultChecked={editUserData.isReported} onClick={handleIsReported} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="Banned">
                  <Form.Check type="checkbox" label="Ban User" name='isBanned' defaultChecked={editUserData.isBanned} onClick={handleIsBanned} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="suspened">
                  <Form.Check type="checkbox" label="Suspend User" name='isSuspended' defaultChecked={editUserData.isSuspended} onClick={handleIsSuspended} />
                </Form.Group>

                {editUserData.isSuspended &&
                  <Form.Group className="mb-3" controlId="suspened">
                    <Form.Label>Suspention Date: </Form.Label>
                    <Form.Control type="datetime-local" placeholder='Choose a date' onChange={handleExpires} name='expires' value={editUserData.expires ? editUserData.expires : moment().format('yyyy-MM-DDThh:mm')} min={moment().format('yyyy-MM-DD')} />
                  </Form.Group>
                }

                <Form.Group className="mb-3" controlId="info">
                  <Form.Label>About</Form.Label>
                  <Form.Control type="text" placeholder="Hey there, i am using Rao Chat!" name="info" value={editUserData.info} onChange={handleEditInputChange} />
                  {error.errorFor === "info" && <p style={{ color: 'red' }} > {error.message} </p>}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={toggleEditModal}>
                Close
              </Button>
              <Button variant="success" onClick={editUser}>
                Save Changes
              </Button> */}
              <button onClick={toggleEditModal} className="adminmodal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
              <button onClick={editUser} className="adminmodal_btn" id="send_btn" type="submit">update user</button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAdminAddModal}>
            <Modal.Header onClick={toggleAdminAddModal} >
              <Modal.Title>Add Admin User</Modal.Title>
              <RxCross2 size={25} />
            </Modal.Header>

            <Modal.Body>
              <Form noValidate validated={validated} onSubmit={addAdminUser}>
                <Form.Group className="mb-3" controlId="fullname">
                  <Form.Label>Name of Admin: </Form.Label>
                  <Form.Control type="text" placeholder="Enter name" name="admin_name" onChange={handleAdminInputChange} required />
                  {error.errorFor === "admin_name" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="phonenumber">
                  <Form.Label>Username: </Form.Label>
                  <Form.Control type='text' placeholder="Enter username" name="username" onChange={handleAdminInputChange} required />
                  {error.errorFor === "username" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="info" style={{ position: 'relative'}}>
                  <Form.Label>Password</Form.Label>
                  <span className='icon eye' onClick={toggleEye} style={{ right: '1rem' }}>
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </span>
                  <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Enter password" name="password" onChange={handleAdminPassword} required />
                  {error.errorFor === "password" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="phonenumber">
                  <Form.Label>Confirm Password: </Form.Label>
                  <Form.Control type='password' placeholder="Enter Confirm Password" name="confirm_password" onChange={handleAdminConfirmPassword} required />
                  {error.errorFor === "confirm_password" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
                </Form.Group>

                <Form.Group style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* <Button variant="secondary" onClick={toggleAdminAddModal} style={{ marginRight: '1rem' }}> Close </Button>
                  <Button variant="success" type='submit'> Add Admin </Button> */}
                  <button onClick={toggleAdminAddModal} className="adminmodal_btn" id="close_btn" style={{ marginRight: '1rem' }} > Close </button>
                  <button className="adminmodal_btn" id="send_btn" type="submit">Add Admin</button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </main>
    </div>
  )
}

export default UserDirectory;