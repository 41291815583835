import React, { useState } from "react";
import '../styles/login.css'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import config from '../config';
// import Forgetpassword from "./Forgetpassword";
import Alert from "../components/Alert";
// import Loading from "../components/Loadingbutton";

const mainUrl = config.baseurl;

const Login = () => {

    const navigate = useNavigate()
    const [showicon, setShowicon] = useState(false)
    const [showForgetPasswordModal, setForgetPasswordModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loginInputtext, setLoginInputtext] = useState({ username: '', password: '' });
    const [email, setEmail] = useState("");
    const [error, setError] = useState({ errorFor: '', message: '', isError: false });
    const [showAlert, setShowAlert] = useState({ title: '', message: '', type: '', isShow: false });
    // const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const handleiconclick = () => {
        setShowicon(!showicon)
    }

    const toggleForgetPasswordModal = () => {
        return setForgetPasswordModal(!showForgetPasswordModal);
    }

    const openForgetPasswordModal = () => {
        toggleForgetPasswordModal();
    }

    const handleEmailInputChange = (e) => {
        let length = e.target.value.length;
        let element = document.getElementsByName(e.target.name)[0];
        if (length === 0) {
            element.style.boxShadow = '1px 1px 3px red, -1px -1px 3px red';
            setError({ errorFor: e.target.name, message: 'This field is required!', isError: true });
            setEmail(e.target.value)
        }
        if (length > 0) {
            element.style = 'none';
            setError({ errorFor: '', message: '', isError: false });
            setEmail(e.target.value)
        }
    }

    const verifyEmail = async (event) => {
        event.preventDefault();

        try {
            const response = await axios({
                method: 'post',
                url: `${mainUrl}/api/verifyEmail`,
                data: {
                    email
                }
            });

            let data = response.data;
            setIsLoading(true)
            if (data.status === 200) {
                if (data.data.isVerifed) {
                    let token = data.data && data.data.admin && data.data.admin.token

                    const response = await axios({
                        method: 'post',
                        url: `${mainUrl}/api/sendEmail`,
                        data: {
                            email,
                            url: `localhost:3000/forget-password`
                        },
                        headers: {
                            "content-type": "application/json",
                            "authorization": `${token}`
                        }
                    });
                    const res = response.data;
                    setIsLoading(false)
                    if (res.status === 200) toggleForgetPasswordModal()
                    setShowAlert({ title: "Alert", message: `You will receive email with forget password link shortly.`, type: 'success', isShow: true });
                }
            }
            else {
                if (email === '') {
                    setError({ errorFor: "email", message: "Email is required", isError: true })
                    setIsLoading(false)
                } else {
                    setError({ errorFor: "email", message: "Email is not exists", isError: true })
                    setIsLoading(false)
                }
                // console.log("Email is not exits ", data);
            }
            setEmail('')
        }
        catch (error) {
            setEmail('')
            setIsLoading(false)
            setShowAlert({ title: "Alert", message: "Internal Server Error", type: 'danger', isShow: true });
        }
    }

    const handleLoginInputchnage = (e) => {
        let length = e.target.value.length;
        let element = document.getElementsByName(e.target.name)[0];
        if (length === 0) {
            setError({ errorFor: e.target.name, message: 'This field is required!', isError: true });
            setLoginInputtext({ ...loginInputtext, [e.target.name]: e.target.value });
        }
        if (length > 0) {
            element.style = 'none';
            setError({ errorFor: '', message: '', isError: false });
            setLoginInputtext({ ...loginInputtext, [e.target.name]: e.target.value });
        }
    }

    const handleLoginSubmit = async (event) => {
        event.preventDefault()
        try {
            const response = await axios({
                method: 'post',
                url: `${mainUrl}/api/verifyAdmin`,
                data: {
                    username: loginInputtext.username,
                    password: loginInputtext.password
                }
            });
            const data = response.data;
            if (data.status === 200) {
                if (data.data.login === true) {
                    localStorage.setItem('token', data.data.token);
                    localStorage.setItem('username', data.data.username);
                    localStorage.setItem('admin_name', data.data.name);
                    localStorage.setItem('type', data.data.type);
                    return navigate('/');
                } else {
                    // console.log('invalid credential')
                    setShowAlert({ title: "Alert", message: "Invalid Credential", type: 'danger', isShow: true });
                }
            }
            else {
                console.log("response in else ", data);
            }
        }
        catch (error) {
            setShowAlert({ title: "Alert", message: "Internal Server Error", type: 'danger', isShow: true });
            console.log(error)
        }
    }

    const handleAlert = () => {
        setShowAlert({ title: '', message: '', type: '', isShow: false })
    }

    return (
        <>
            <div className="content">
                <div className="width1">
                </div>
                <div className="width2">
                    <div className="form">
                        <h3 className="Text">Login Account</h3>
                        <form onSubmit={handleLoginSubmit}>
                            <div className="input-container">
                                <div className="passwordbox">
                                    <label className="input">Username </label>
                                    <input className="logininput" type="text" name="username" required onChange={handleLoginInputchnage} />
                                </div>
                                {error.errorFor === "username" && <div className="errwidth"><p style={{ color: 'red', textAlign: 'left', margin: 4 }} > {error.message} </p></div>}
                            </div>
                            <div className="input-container">
                                <div className="passwordbox">
                                    <label className="input">Password </label>
                                    <input type={showicon ? "text" : "password"} className="input-field logininput" name="password" required onChange={handleLoginInputchnage} />
                                    {showicon ? <AiOutlineEye className="icons" onClick={handleiconclick} size={30} color="" /> : <AiOutlineEyeInvisible className="icons" size={30} onClick={handleiconclick} />}
                                </div>
                                {error.errorFor === "password" && <div className="errwidth"><p style={{ color: 'red', textAlign: 'left', margin: 4 }} > {error.message} </p></div>}
                            </div>
                            <div className="forgetpasswordcontainer">
                                <Link to='' className="forget-password" onClick={openForgetPasswordModal}>Forget password &#x3F;</Link>
                            </div>
                            <div className="button-container">
                                <input type="submit" />
                            </div>
                            <div id="showAlert">
                                {showAlert.isShow && <Alert title={showAlert.title} message={showAlert.message} type={showAlert.type} isShow={showAlert.isShow} handleAlert={handleAlert} />}
                            </div>
                        </form>
                    </div>
                </div >
                <Modal show={showForgetPasswordModal}>
                    <Modal.Header onClick={toggleForgetPasswordModal} >
                        <Modal.Title>Forget password</Modal.Title>
                        <RxCross2 size={25} />
                    </Modal.Header>

                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={verifyEmail}>
                            <Form.Group className="mb-3" controlId="Email">
                                <Form.Label>Email: </Form.Label>
                                <Form.Control type='email' placeholder="Email" name="Email" value={email} required onChange={handleEmailInputChange} />
                                {error.isError && error.errorFor === "email" && <p style={{ color: 'red', textAlign: 'left' }} > {error.message} </p>}
                            </Form.Group>

                            <Form.Group style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="modal_btn" id="close_btn" onClick={toggleForgetPasswordModal} style={{ marginRight: '1rem' }}> Close </button>
                                <button className="modal_btn" id="send_btn" type="submit">Send
                                    <span>{isLoading ? <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''}</span>
                                </button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    )
}

export default Login
